<template>
  <v-dialog
    persistent
    content-class="calendar-event-editor-dialog"
    max-width="618px"
    v-model="show"
    @click:outside="handleClickOutside"
  >
    <v-card>
      <v-form
        v-model="meta.valid"
        class="text-center"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>{{ title }}</v-card-title>

        <v-card-text>
          <v-tabs v-model="meta.tab" class="mb-5" fixed-tabs>
            <v-tab key="event">
              <v-icon class="mr-2">$calendar</v-icon>
              Termin
            </v-tab>
            <v-tab key="participants">
              <v-icon class="mr-2">$participants</v-icon>
              Teilnehmer
            </v-tab>
            <v-tab key="planning">
              <v-icon class="mr-2">$list</v-icon>
              Planung
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="meta.tab">
            <v-tab-item key="event">
              <v-row dense>
                <v-col cols="12" sm="10">
                  <conference-subject-input
                    :readonly="!isMyConference"
                    required
                    v-model="calendarEvent.conference.subject"
                  ></conference-subject-input>
                </v-col>

                <v-col cols="12" sm="2">
                  <color-input
                    :readonly="!isMyConference"
                    v-model="calendarEvent.color"
                  ></color-input>
                </v-col>
              </v-row>

              <v-row class="mb-3" dense>
                <v-col>
                  <date-time-input
                    dateLabel="Startdatum"
                    :readonly="!isMyConference"
                    timeLabel="Startzeitpunkt"
                    :value="calendarEvent.start"
                    @input="handleStartChanged"
                  ></date-time-input>
                </v-col>
              </v-row>

              <v-row class="mb-3" dense>
                <v-col>
                  <date-time-input
                    dateLabel="Enddatum"
                    :readonly="!isMyConference"
                    timeLabel="Endzeitpunkt"
                    v-model="calendarEvent.end"
                  ></date-time-input>
                </v-col>
              </v-row>

              <v-row class="mb-3" dense>
                <v-col>
                  <recurrence-input
                    :readonly="!isMyConference"
                    :start="calendarEvent.start"
                    v-model="calendarEvent.recurrence"
                  ></recurrence-input>
                </v-col>
              </v-row>

              <v-row class="mb-3" dense>
                <v-col>
                  <v-textarea
                    auto-grow
                    label="Beschreibung"
                    rows="1"
                    v-model="calendarEvent.conference.description"
                    :readonly="!isMyConference"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item key="participants">
              <v-row v-if="isMyConference" class="mb-3" dense>
                <v-col>
                  <participant-input
                    label="Teilnehmer hinzufügen"
                    :existing-participants="
                      calendarEvent.conference.participants
                    "
                    @participant-selected="
                      calendarEvent.conference.participants.push($event)
                    "
                  ></participant-input>
                </v-col>
              </v-row>

              <v-row class="mb-3" dense>
                <v-col>
                  <share-link-input
                    :readonly="!isMyConference"
                    v-model="calendarEvent.conference.shareLinkUuid"
                  ></share-link-input>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <participant-list
                    :is-new-conference="isNewConference"
                    :owner="conferenceOwner"
                    :readonly="!isMyConference"
                    class="text-left"
                    v-model="calendarEvent.conference.participants"
                  ></participant-list>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item key="planning">
              <v-row class="fill-height">
                <v-col>
                  <planning-calendar
                    :end="calendarEvent.end"
                    :id="calendarEvent.id"
                    :participants="calendarEvent.conference.participants"
                    :start="calendarEvent.start"
                    @time-changed="handlePlanningCalendarTimeChanged"
                  ></planning-calendar>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <temeno-circle-button
            action
            title="löschen"
            v-if="isMyConference && !isNewConference"
            @click="handleClickDelete"
          >
            {{ meta.deleteFirstStep ? "$delete" : "$alert" }}
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="jetzt teilnehmen"
            v-if="!isNewConference"
            @click="handleClickLaunch"
          >
            $video_call
          </temeno-circle-button>

          <v-spacer></v-spacer>

          <temeno-circle-button
            action
            title="abbrechen"
            @click="handleClickCancel"
          >
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            :disabled="!meta.valid"
            title="speichern"
            type="submit"
            v-if="isMyConference"
          >
            {{ !meta.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>

        <v-expand-transition>
          <div class="text-left" v-show="meta.showUnsavedChangesWarning">
            <v-divider></v-divider>

            <v-card-text>
              <strong>Änderungen verwerfen?</strong>
              Alle Änderungen gehen unwiderruflich verloren.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <temeno-circle-button
                action
                title="weiter bearbeiten"
                @click="handleClickContinueEditingButton"
              >
                $arrow_left
              </temeno-circle-button>

              <temeno-button action @click="handleClickForceCancelButton">
                Änderungen verwerfen
              </temeno-button>
            </v-card-actions>
          </div>
        </v-expand-transition>
      </v-form>
    </v-card>

    <v-dialog persistent max-width="586px" v-model="meta.conflict.showDialog">
      <v-card>
        <v-card-title>Terminkonflikt</v-card-title>

        <v-card-text>
          <v-alert type="warning" v-if="meta.conflict.users.length > 0">
            <template v-if="meta.conflict.users.length === 1">
              Beim Teilnehmer {{ meta.conflict.users[0].name }} wurde ein
              Terminkonflikt festgestellt.
            </template>
            <template v-else>
              Bei {{ meta.conflict.users.length }} Teilnehmern wurde ein
              Terminkonflikt festgestellt.
            </template>
          </v-alert>

          <v-alert
            type="warning"
            v-if="meta.conflict.usersOutsideWorkingTimes.length > 0"
          >
            <template
              v-if="meta.conflict.usersOutsideWorkingTimes.length === 1"
            >
              Für den Teilnehmer
              {{ meta.conflict.usersOutsideWorkingTimes[0].name }} liegt der
              Termin außerhalb der regulären Arbeitszeit.
            </template>
            <template v-else>
              Für {{ meta.conflict.usersOutsideWorkingTimes.length }} Teilnehmer
              liegt der Termin außerhalb der regulären Arbeitszeit.
            </template>
          </v-alert>

          <h3 class="mt-6">Automatisch ermittelte Ausweichtermine:</h3>

          <v-radio-group
            hide-details
            v-model="meta.conflict.alternatives.selection"
          >
            <v-radio
              :key="alternative.unix()"
              :label="alternative.format('LLLL') + ' Uhr'"
              :value="alternative"
              v-for="alternative in meta.conflict.alternatives.choices"
            ></v-radio>
            <p class="pl-8 my-0" v-show="meta.conflict.alternatives.loading">
              Suche nach passenden Ausweichterminen…
            </p>
            <p
              class="pl-8 my-0"
              v-show="
                !meta.conflict.alternatives.loading &&
                meta.conflict.alternatives.choices.length === 0
              "
            >
              Leider konnte kein passender Ausweichtermin ermittelt werden.
            </p>
            <p class="pl-8 py-3 mb-0">
              <a class="mr-2" href="#" @click.prevent="fetchMoreAlternatives"
                >weitere Ausweichtermine ermitteln</a
              >
              <v-progress-circular
                indeterminate
                color="primary"
                size="16"
                v-show="meta.conflict.alternatives.loading"
              ></v-progress-circular>
            </p>
            <p class="pl-8 mb-6">
              <v-checkbox
                hide-details
                class="mt-0"
                label="reguläre Arbeitszeiten der Teilnehmer berücksichtigen"
                v-model="meta.conflict.considerParticipantWorkingTimes"
              ></v-checkbox>
            </p>
          </v-radio-group>

          <h3 class="mb-6">Wie möchten Sie vorgehen?</h3>

          <temeno-button
            action
            block
            :disabled="meta.conflict.alternatives.selection == null"
            @click="applyAlternative"
          >
            <v-icon left>{{ meta.conflict.alternatives.selection == null ? '$approve_action_disabled' : '$approve' }}</v-icon>
            Ausgewählten Ausweichtermin übernehmen
          </temeno-button>

          <v-divider class="my-4"></v-divider>

          <temeno-button action block @click="handleSubmit(false)">
            <v-icon left>$alert</v-icon>
            Den Termin unverändert speichern
          </temeno-button>

          <v-divider class="my-4"></v-divider>

          <temeno-button action block @click="showPlanningTab">
            <v-icon left>$calendar</v-icon>
            Planungsansicht zeigen
          </temeno-button>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import moment from "moment";
import api from "@/api";

export default {
  name: "CalendarEventEditor",
  components: {
    ColorInput: () => import("@/components/ColorInput"),
    ConferenceSubjectInput: () => import("@/components/ConferenceSubjectInput"),
    DateTimeInput: () => import("@/components/DateTimeInput"),
    ParticipantInput: () => import("@/components/ParticipantInput"),
    ParticipantList: () => import("@/components/ParticipantList"),
    PlanningCalendar: () => import("@/components/PlanningCalendar"),
    RecurrenceInput: () => import("@/components/RecurrenceInput"),
    ShareLinkInput: () => import("@/components/ShareLinkInput"),
    TemenoButton: () => import("@/components/TemenoButton"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      calendarEvent: {
        conference: {
          participants: [],
        },
      },
      meta: {
        conflict: {
          alternatives: {
            choices: [],
            loading: false,
            searchDate: null,
            selection: null,
          },
          showDialog: false,
          users: [],
          usersOutsideWorkingTimes: [],
          considerParticipantWorkingTimes: true,
        },
        deleteFirstStep: true,
        showConflictsDialog: false,
        showUnsavedChangesWarning: false,
        tab: 0,
        valid: true, // TODO
      },
    };
  },
  computed: {
    conferenceOwnerId() {
      if (this.value) {
        return this.value.conference.owner;
      } else {
        return null;
      }
    },
    conferenceOwner() {
      if (this.isMyConference) {
        const { avatar, id, showName } = this.$store.state.user;
        return {
          avatar,
          id,
          name: showName,
          scope: "MODERATE",
        };
      } else {
        return {
          scope: "MODERATE",
          ...this.$store.state.users[this.conferenceOwnerId],
        };
      }
    },
    isNewConference() {
      if (this.value) {
        return !(this.value.id > 0);
      } else {
        return true;
      }
    },
    isMyConference() {
      if (this.isNewConference) {
        return true;
      }
      return this.conferenceOwnerId === this.myUserId;
    },
    myUserId() {
      return this.$store.state.user.id;
    },
    title() {
      if (!this.isMyConference) {
        return this.$t("title.show");
      } else if (this.isNewConference) {
        return this.$t("title.create");
      } else {
        return this.$t("title.edit");
      }
    },
    unsavedChanges() {
      return !_isEqual(this.calendarEvent, this.value);
    },
    userParticipants() {
      return this.calendarEvent.conference.participants.filter(
        (p) => p.type === "user"
      );
    },
    userParticipantUserIds() {
      return this.userParticipants.map((u) => u.userId);
    },
  },
  watch: {
    show(show) {
      if (show) {
        this.$store.dispatch("users/load");
      } else {
        this.meta.conflict = {
          // reset conflict dialog
          alternatives: {
            choices: [],
            loading: false,
            searchDate: null,
            selection: null,
          },
          showDialog: false,
          users: [],
          usersOutsideWorkingTimes: [],
          considerParticipantWorkingTimes: true,
        };
        this.meta.deleteFirstStep = true;
        this.meta.showUnsavedChangesWarning = false;
        this.meta.tab = 0; // reset to first tab
      }
    },
    value(calendarEvent) {
      if (calendarEvent) {
        const mappedParticipants = calendarEvent.conference.participants.map(
          (p) => {
            const { type } = p;
            let avatar, key, name;
            if (type === "user") {
              key = `user:${p.userId}`;
              // enrich avatar and name from users store
              const user = this.$store.state.users[p.userId];
              if (user) {
                avatar = user.avatar;
                name = user.name;
              }
            } else if (type === "contact") {
              key = `contact:${p.contactId}`;
            }
            return { avatar, key, name, ...p };
          }
        );
        this.calendarEvent = _cloneDeep(calendarEvent);
        this.calendarEvent.conference.participants = mappedParticipants;
      }
    },
  },
  methods: {
    applyAlternative() {
      const duration =
        this.calendarEvent.end.getTime() - this.calendarEvent.start.getTime();
      this.calendarEvent.start =
        this.meta.conflict.alternatives.selection.toDate();
      this.calendarEvent.end = new Date(
        this.calendarEvent.start.getTime() + duration
      );
      this.handleSubmit(false);
    },
    cancel(force = false) {
      if (!force && this.unsavedChanges) {
        this.meta.showUnsavedChangesWarning = true;
        const el = document.getElementsByClassName("calendar-event-editor-dialog")[0];
        setTimeout(() => {
          el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
        }, 300);
      } else {
        this.$emit("cancel");
      }
    },
    handleClickCancel() {
      this.cancel();
    },
    handleClickCloseButton() {
      this.cancel();
    },
    handleClickContinueEditingButton() {
      this.meta.showUnsavedChangesWarning = false;
    },
    handleClickDelete() {
      if (this.meta.deleteFirstStep) {
        this.meta.deleteFirstStep = false;
        setTimeout(() => {
          this.meta.deleteFirstStep = true;
        }, 2000);
        return;
      }
      this.meta.deleteFirstStep = true;
      this.$emit("delete");
    },
    handleClickForceCancelButton() {
      this.cancel(true);
    },
    handleClickLaunch() {
      this.meta.deleteFirstStep = true;
      this.$router.push({
        name: "conference",
        params: {
          id: this.value.conference.id,
        },
      });
    },
    handleClickOutside() {
      this.cancel();
    },
    handlePlanningCalendarTimeChanged({ start, end }) {
      this.calendarEvent.start = start;
      this.calendarEvent.end = end;
    },
    handleStartChanged(newStart) {
      const duration = moment(this.calendarEvent.end).diff(
        moment(this.calendarEvent.start)
      );
      this.calendarEvent.start = newStart;
      this.calendarEvent.end = moment(this.calendarEvent.start)
        .add(duration)
        .toDate();
    },
    handleSubmit(preflight = true) {
      this.meta.deleteFirstStep = true;
      if (preflight) {
        api
          .preflight(this.calendarEvent)
          .then((response) => {
            const preflightResult = response.data;
            this.meta.conflict.users = preflightResult.conflicts;
            this.meta.conflict.usersOutsideWorkingTimes =
              preflightResult.outsideWorkingTimes;
            if (
              this.meta.conflict.users.length > 0 ||
              this.meta.conflict.usersOutsideWorkingTimes.length > 0
            ) {
              console.warn("preflight conflicts");
              this.startNewAlternativesSearch();
              this.meta.conflict.showDialog = true;
            } else {
              this.$emit("input", this.calendarEvent);
            }
          })
          .catch((error) => {
            console.error("Error executing preflight request", error);
          });
      } else {
        this.$emit("input", this.calendarEvent);
      }
    },
    increaseAlternativeSearchDate() {
      this.meta.conflict.alternatives.searchDate.add(1, "day");
    },
    async searchAlternatives() {
      const userIds = this.userParticipantUserIds;
      const date =
        this.meta.conflict.alternatives.searchDate.format("YYYY-MM-DD");
      const duration = moment.duration(
        this.calendarEvent.end.getTime() - this.calendarEvent.start.getTime()
      );
      const { considerParticipantWorkingTimes } = this.meta.conflict;
      this.meta.conflict.alternatives.loading = true;
      return api
        .getFreePeriods(
          userIds,
          date,
          duration.toISOString(),
          considerParticipantWorkingTimes
        )
        .then((response) => {
          const freePeriods = response.data;
          freePeriods.forEach((freePeriod) => {
            const freePeriodStart = moment(freePeriod.start);
            const freePeriodEnd = moment(freePeriod.end);
            for (let i = 0; i < 3; i++) {
              const choice = freePeriodStart.clone().add(i, "hour");
              const choiceEnd = choice.clone().add(duration);
              if (choiceEnd.isAfter(freePeriodEnd)) {
                break;
              }
              this.meta.conflict.alternatives.choices.push(choice);
            }
          });
        })
        .finally(() => (this.meta.conflict.alternatives.loading = false));
    },
    async startNewAlternativesSearch() {
      const { alternatives } = this.meta.conflict;

      // reset search date
      alternatives.searchDate = moment(this.calendarEvent.start);

      // reset choices
      alternatives.choices = [];

      // try to determine at least 3 alternatives initially
      await this.fetchAlternatives(3);
    },
    async fetchAlternatives(min, maxTries = 28) {
      const { alternatives } = this.meta.conflict;

      // try to fetch at least "min" new alternatives
      const minLength = alternatives.choices.length + min;
      await this.searchAlternatives();
      for (
        let i = 0;
        i < maxTries && alternatives.choices.length < minLength;
        i++
      ) {
        this.increaseAlternativeSearchDate();
        await this.searchAlternatives();
      }
    },
    fetchMoreAlternatives() {
      this.increaseAlternativeSearchDate();
      // try to determine at least 1 more alternative
      this.fetchAlternatives(1);
    },
    showPlanningTab() {
      this.meta.conflict.showDialog = false;
      this.meta.tab = 2;
    },
  },
  i18n: {
    messages: {
      de: {
        title: {
          create: "Neue Konferenz erstellen",
          edit: "Konferenz bearbeiten",
          show: "Konferenz anzeigen",
        },
      },
    },
  },
};
</script>
